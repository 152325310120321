import {Modal} from 'react-bootstrap-v5'
import {Dispatch, SetStateAction} from 'react'

export function ModalConfirm(props: {
  translateObj: any
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  onConfirmInactivation: () => void
  loading: boolean
}) {
  const {translateObj, show, loading, setShow, onConfirmInactivation} = props

  const handleClose = () => {
    setShow(!show)
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className={'text-danger'}>
          {translateObj('MESSAGE.INACTIVEORDER.TITLE')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <div className='fs-6'>{translateObj('MESSAGE.INACTIVEORDER.DESCRIPTION')}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <>
            <span className='indicator-progress' style={{display: 'block'}}>
              {translateObj('MESSAGE.LOADING') + ' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </>
        ) : (
          <>
            <button type='button' className='btn btn-default' onClick={handleClose}>
              {translateObj('BTN.CANCEL')}
            </button>

            <button type='submit' className='btn btn-danger' onClick={onConfirmInactivation}>
              <i className='fa fa-times'></i>
              <span className='mx-2'>{translateObj('BTN.CONFIRM')}</span>
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}
