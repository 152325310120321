import { useState } from 'react'
import { IValues } from '../../../CreateProject'
import { maskCep, searchCategory, searchDecoration, searchProduct, searchType } from '../../../../../utils'
import UseCredit from '../../useCredit';

export default function Step3(props: { translateObj: any, order: any, values: IValues, loading: any, typeSelect: any, user: any }) {
  const { translateObj, order, values, loading, typeSelect, user } = props;
  const [finishedPayment, setFinishedPayment] = useState((user?.type === 'Squad' || user?.type === 'Squadinator') ? true : false);

  const finishedPaymentScreen = () => {
    setFinishedPayment(true)
    return false
  }

  return (
    <>
      <div className='w-100'>
        {!finishedPayment ? (
          <>
            <h2 className='fw-bolder text-dark'>{translateObj("MESSAGE.CONFIRMATION")}</h2>

            <div className='text-gray-400 fw-bold fs-6 mb-10'>
              {translateObj("MESSAGE.CONFIRMATION.DESCRIPTION")}
            </div>

            {/* details */}
            <div className='row align-items-start fs-5'>
              <div className='col-12'>
                <h4 className='mb-3'>{translateObj("MESSAGE.IMMOBILE")}</h4>

                <div className='alert alert-secondary p-5 px-10'>
                  <div>
                    <b>{translateObj("MESSAGE.IDENTIFICATION")}:</b>
                    <span className='px-3'>{values.name}</span>
                  </div>
                  <div>
                    <b>{translateObj("MESSAGE.CATEGORY")}:</b>
                    <span className='px-3'>{searchCategory(values.category)}</span>
                  </div>
                  <div>
                    <b>{translateObj("MESSAGE.VALUERENTSALE")}:</b>
                    <span className='px-3'>{`R$ ` + values.price}</span>
                  </div>
                  <div>
                    <b>{translateObj("MESSAGE.TYPE")}:</b>
                    <span className='px-3'>{searchType(values.type)}</span>
                  </div>
                  {!typeSelect?.express && (
                    <div>
                      <b>{translateObj("MESSAGE.PROPERTYSTANDARD")}:</b>
                      <span className='px-3'>{searchProduct(values.product)}</span>
                    </div>
                  )}
                  <div>
                    <b>{translateObj("MESSAGE.ZIPCODE")}:</b>
                    <span className='px-3'>{maskCep(values.zip_code)}</span>
                  </div>
                  {!typeSelect?.express && (
                    <div>
                      <b>{translateObj("MESSAGE.DECORATIONSTYLE")}:</b>
                      <span className='px-3'>{searchDecoration(values.decoration)}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12 mt-10'>
                <h4 className='mb-3'>{translateObj("MESSAGE.DETAILS.AMBIENCE")}</h4>

                <div className='alert alert-secondary p-5 px-10'>
                  {values.photos && values.photos.map((item, key) => (
                    <div key={key}>
                      {key !== 0 && <hr />}
                      <div className='fw-bold text-dark text-uppercase mb-2'>{key + 1}° {translateObj("MESSAGE.AMBIENCE")}</div>
                      <div>
                        <b>{translateObj("MESSAGE.NAME")}:</b>
                        <span className='px-3'>{item.type}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {!loading && <UseCredit translateObj={translateObj} order={order} typeSelectOrder={typeSelect} functionPayment={finishedPaymentScreen} />}

          </>) : (
          <div className='card'>
            <div className="card-body">
              <h1 className="card-title text-success d-flex align-items-center">
                <i className='fa fa-check-circle text-success fa-2x'></i>
                <span className='mx-3'>{translateObj("MESSAGE.NOWTEAM.TITLE")}</span>
              </h1>
              <div className="card-text my-5 fs-5">
                <div>
                  {translateObj("MESSAGE.NOWTEAM.DESCRIPTION")}
                </div>
                <div className='alert alert-warning mt-5'>
                  <b>{translateObj("MESSAGE.ATENTION")}:</b>
                  <br />
                  {translateObj("MESSAGE.ATENTION.DESCRIPTION")}
                </div>
                <a href='report' className='fs-5 btn btn-default mt-5 w-100'>
                  <i className='fa fa-search me-2'></i>
                  {translateObj("MESSAGE.CONSULTPROJECTS")}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}