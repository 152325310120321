/* eslint-disable array-callback-return */
import {Field, ErrorMessage, FieldArray} from 'formik'
import {Dispatch, SetStateAction, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import toast from 'react-hot-toast'
import {Tooltip, OverlayTrigger} from 'react-bootstrap-v5'

const ViewAmbience = (props: {
  translateObj: any
  number: any
  file: any
  setFile: Dispatch<SetStateAction<any[]>>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  values: any
  typeSelect: any
}) => {
  const {translateObj, number, file, setFile, setFieldValue, values, typeSelect} = props
  const array = number - 1

  const typeAmbiences = typeSelect?.celer
    ? [
        {value: '', name: translateObj('MESSAGE.SELECT')},
        {value: 'Quarto', name: translateObj('MESSAGE.ROOM')},
        {value: 'Varanda', name: translateObj('MESSAGE.BALCONY')},
        {value: 'Banheiro', name: translateObj('MESSAGE.BATHROOM')},
        {value: 'Cozinha', name: translateObj('MESSAGE.KITCHEN')},
        {value: 'Sala de Estar', name: translateObj('MESSAGE.LIVINGROOM')},
        {value: 'Sala integrada com Cozinha', name: translateObj('MESSAGE.INTEGRATEDROOM')},
        {value: 'Área Gourmet/Churrasqueira', name: translateObj('MESSAGE.GOURMETAREA')},
        {value: 'Outros', name: translateObj('MESSAGE.OTHERS')},
      ]
    : [
        {value: '', name: translateObj('MESSAGE.SELECT')},
        {value: 'Quarto', name: translateObj('MESSAGE.ROOM')},
        {value: 'Cozinha', name: translateObj('MESSAGE.KITCHEN')},
        {value: 'Sala de Estar', name: translateObj('MESSAGE.LIVINGROOM')},
      ]

  const changeFile = (files: any, key: any) => {
    if (typeSelect?.celer && files.length > 3) {
      toast.error(translateObj('VALID.MAXPHOTOS.AMBIENCE'), {position: 'bottom-right'})
      return
    }

    setFieldValue(`photos[${key}].sendFile`, 'sendFile')

    const arrayFile = file
    if (!Array.isArray(arrayFile[key])) {
      arrayFile[key] = []
    }

    arrayFile[key] = [...arrayFile[key], ...files]

    setFile([...arrayFile])
  }

  const changeAmbience = (value: any, key: any) => {
    if (value !== 'Outros') {
      setFieldValue(`photos[${key}].type`, value)
      document.getElementById(`type${array}`)?.classList.add('d-none')
      document.getElementById(`errorType${array}`)?.classList.remove('d-none')
    } else {
      setFieldValue(`photos[${key}].type`, '')
      document.getElementById(`type${array}`)?.classList.remove('d-none')
      document.getElementById(`errorType${array}`)?.classList.add('d-none')
    }
  }

  const handleRemoveFile = (key: number, index: number) => {
    const arrayFile = [...file]
    arrayFile[key].splice(index, 1)
    setFile(arrayFile)
    if (arrayFile[key].length === 0) {
      setFieldValue(`photos[${key}].sendFile`, '')
    }
  }

  const renderTooltip = (title: string, content?: string) => (
    <Tooltip id='button-tooltip'>{content || title}</Tooltip>
  )

  return (
    <div className='mb-15' id={`ambienceView${number}`}>
      <h5 className='text-primary pb-2 mb-10 border-bottom text-uppercase'>
        {number}° {translateObj('MESSAGE.AMBIENCE')}
      </h5>
      <div className={`fv-row mb-7 ${values.type !== 'Residential' && 'd-none'}`}>
        <label className='fs-6 fw-bold form-label required'>
          {translateObj('MESSAGE.AMBIENCE')}
          <OverlayTrigger
            placement='top'
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip(translateObj('MESSAGE.AMBIENCE.TOOLTIP'))}
          >
            <i className='fas fa-question-circle m-1'></i>
          </OverlayTrigger>
        </label>

        <select
          className='form-select form-select-lg form-select-solid'
          onChange={(e: any) => changeAmbience(e.target.value, array)}
        >
          {typeAmbiences.map((item, key) => (
            <option key={key} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
        <div className='text-danger mt-2' id={`errorType${array}`}>
          <ErrorMessage name={`photos[${array}].type`} />
        </div>
      </div>

      <div
        className={`fv-row mb-7 ${values.type === 'Residential' && 'd-none'}`}
        id={`type${array}`}
      >
        <label className='fs-6 fw-bold form-label required'>
          {translateObj('MESSAGE.DEFINITION')}
        </label>

        <Field
          name={`photos[${array}].type`}
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`photos[${array}].type`} />
        </div>
      </div>

      <div className='fv-row mb-7'>
        <label className='fs-6 fw-bold form-label required'>
          {translateObj('MESSAGE.SENDDOCUMENTPHOTO')}
          <OverlayTrigger
            placement='top'
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip(translateObj('MESSAGE.SENDDOCUMENTPHOTO.TOOLTIP'))}
          >
            <i className='fas fa-question-circle m-1'></i>
          </OverlayTrigger>
        </label>

        <Field
          type='file'
          multiple
          accept={typeSelect?.celer ? 'image/*,application/pdf' : 'image/*'}
          name={`photos[${array}].file`}
          className='form-control form-control-lg form-control-solid'
          onChange={(e: any) => changeFile(e.target.files, array)}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`photos[${array}].sendFile`} />
        </div>
        <div className='text-gray-400 fw-bold fs-6 mt-2'>
          {translateObj('MESSAGE.SENDDOCUMENTPHOTO.WARN')}
        </div>
        <div className='mt-4'>
          {/* Verificar se file[array] é um array antes de mapear */}
          {Array.isArray(file[array]) &&
            file[array].map((photo: any, index: any) => (
              <div
                key={index}
                className='d-flex justify-content-between align-items-center mb-2 border p-2 rounded'
              >
                {/* Nome do arquivo alinhado à esquerda */}
                <span className='me-2' style={{maxWidth: '70%', wordBreak: 'break-word'}}>
                  {photo.name || photo.fileName}
                </span>

                {/* Botão de Remover alinhado à direita */}
                <button
                  type='button'
                  className='btn btn-danger btn-sm align-self-sm-center'
                  onClick={() => handleRemoveFile(array, index)}
                >
                  <i className='fas fa-trash'></i>
                </button>
              </div>
            ))}
        </div>
      </div>

      <div className='fv-row mb-7'>
        <label className='fs-6 fw-bold form-label'>
          {translateObj('MESSAGE.LINKSITE')}
          <span className='optional'>({translateObj('MESSAGE.OPTIONAL')})</span>
        </label>

        <Field
          name={`photos[${array}].link`}
          placeholder={translateObj('MESSAGE.PROVIDELINK')}
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`photos[${array}].link`} />
        </div>
      </div>

      <div className={`fv-row mb-7 ${typeSelect?.express && 'd-none'}`}>
        <label className='fs-6 fw-bold form-label'>
          {translateObj('MESSAGE.COMPLEMENT')}
          <span className='optional'>({translateObj('MESSAGE.OPTIONAL')})</span>
        </label>

        <Field
          as='textarea'
          maxLength={200}
          placeholder={translateObj('MESSAGE.COMPLEMENT.PLACEHOLDER')}
          name={`photos[${array}].description`}
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-muted mt-1 text-end'>{translateObj('MESSAGE.COMPLEMENT.CHAR')}</div>
        <div className='text-danger mt-2'>
          <ErrorMessage name={`photos[${array}].description`} />
        </div>
      </div>
    </div>
  )
}

export default function Step2(props: {
  translateObj: any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  setValues: (values: React.SetStateAction<any>, shouldValidate?: boolean | undefined) => void
  file: any
  setFile: Dispatch<SetStateAction<any[]>>
  loading: boolean
  values: any
  typeSelect: any
}) {
  const {translateObj, setFieldValue, file, setFile, loading, values, typeSelect} = props
  const [count, setCount] = useState([1, 2, 3])

  return (
    <>
      <div className='w-100'>
        <h2 className='fw-bolder text-dark'>{translateObj('MESSAGE.PHOTOSIMMOBILE')}</h2>

        <div className='text-gray-400 fw-bold fs-6 mb-15'>
          {translateObj('MESSAGE.PHOTOSIMMOBILE.DESCRIPTION')}
          <br />
          <div className='d-grid gap-2 mt-2'>
            {translateObj('MESSAGE.PHOTOSIMMOBILE.DESCRIPTION.MANUAL')}
            <a href='media/tutorial/manual_fotos.pdf' target='_target'>
              <i className='fa fa-info-circle me-2'></i>
              {translateObj('MESSAGE.MANUAL.PDF')}
            </a>
            <a href='https://youtu.be/NCCHj-WoRlo' target='_target'>
              <i className='fa fa-info-circle me-2'></i>
              {translateObj('MESSAGE.MANUAL.VIDEO')}
            </a>
          </div>
        </div>

        <div>
          <FieldArray
            name='photos'
            render={(arrayHelpers) => {
              return (
                <div>
                  {count.map((key) => {
                    return (
                      <ViewAmbience
                        translateObj={translateObj}
                        key={key}
                        number={key}
                        file={file}
                        setFile={setFile}
                        setFieldValue={setFieldValue}
                        values={values}
                        typeSelect={typeSelect}
                      />
                    )
                  })}
                </div>
              )
            }}
          />
          <div className='btn btn-primary' onClick={() => setCount([...count, count.length + 1])}>
            <i className='fa fa-plus me-2'></i>
            {translateObj('MESSAGE.ADDAMBIENCE')}
          </div>
        </div>

        <div className='d-flex flex-stack pt-15'>
          <div className='mr-2'></div>
          <div>
            {loading ? (
              <span className='text-center indicator-progress' style={{display: 'block'}}>
                {translateObj('MESSAGE.LOADING') + ' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <button
                type='submit'
                className='btn btn-lg btn-dark text-white me-3'
                id='btnSubmitPhotos'
              >
                <span className='indicator-label'>
                  {translateObj('BTN.NEXT')}
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
