import { AsideMenuItem } from "../AsideMenuItem";

export default function MenuCompany(props: { translateObj: any }) {
  let { translateObj } = props;

  return (
    <>
      <AsideMenuItem
        to='/create'
        icon='/media/icons/duotune/general/gen041.svg'
        title={translateObj("MESSAGE.NEWPROJECT")}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/report'
        icon='/media/icons/duotune/general/gen005.svg'
        title={translateObj("MESSAGE.CONSULTPROJECTS")}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/credit/buy'
        icon='/media/icons/duotune/finance/fin008.svg'
        title={translateObj("MESSAGE.BUYCREDITANDIMAGE")}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/credit/report'
        icon='/media/icons/duotune/finance/fin007.svg'
        title={translateObj("MESSAGE.MYPAYMENTS")}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/company/detail'
        icon='/media/icons/duotune/finance/fin006.svg'
        title={translateObj("MESSAGE.MYDATAS")}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/contact'
        icon='/media/icons/duotune/communication/com003.svg'
        title={translateObj("MESSAGE.CONTACTUS")}
        fontIcon='bi-layers'
      />
    </>
  )
}