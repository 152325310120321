import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { IFilter, initialFilterAdmin, numberOfLines } from "../ReportProject";
import { deleteAdminOrderId, getAdminOrder } from '../../../services/Api'
import { maskCep, searchCategory, searchDecoration, searchStatus, searchStatusColor, searchType, typeProductName } from "../../../utils";
import { setTimeout } from "timers";
import moment from "moment";
import { ModalConfirm } from "../components/ModalConfirm";

export default function ReportTeam(props: { translateObj: any }) {
  let { translateObj } = props;

  const [params, setParams] = useState<any>(initialFilterAdmin);
  const [filter, setFilter] = useState<IFilter>(initialFilterAdmin)
  const [orders, setOrders] = useState<any[]>([])
  const [modalConfirm, setModalConfirm] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [loading, setLoading] = useState(false)
  const { status } = useParams<any>();

  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listOrders, setListOrders] = useState<any[]>([])

  const changeParam = (element: any) => {
    const value = element.target.value
    const oldParams = params
    oldParams[`${element.target.name}`] = value
    setParams({ ...oldParams })
    verifyEnter(element)
  }

  const verifyEnter = (e: any) => {
    if (e.keyCode === 13) sendFilter()
  }

  const sendFilter = () => {
    setFilter({ ...params })
  }

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    setListOrders(orders.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListOrders = (array: []) => {
    setOrders(array)
    setListOrders(array.slice(0, 10))

    verifyTotalPages(array)
  }

  const confirmModal = (item: any) => {
    setOrderId(item.id)
    setModalConfirm(true)
  }

  const onConfirmInactivation = async () => {
    setLoading(true)

    await deleteAdminOrderId(orderId).then(() => {
      getAdminOrder(filter).then((data) => {
        verifyListOrders(data)
      })
    })

    setLoading(false)
    setModalConfirm(!modalConfirm)
  }

  useEffect(() => {
    getAdminOrder(filter).then((data) => {
      verifyListOrders(data.data)
    })
  }, [filter])

  useEffect(() => {
    setTimeout(() => {
      let filterInfo = filter
      filterInfo.status = status ? status : ''
      setFilter({ ...filterInfo })
    }, 100)
  }, [])

  return (
    <>
      <h1 className='mb-10'>{translateObj("MESSAGE.CONSULTGENERAL")}</h1>
      <div className='card'>
        <div className='card-body'>
          <h3 className='mb-5'>{translateObj("MESSAGE.FILTER")}:</h3>
          <div className='mb-10'>
            <div className='mb-5'>
              <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.COMPANY")}</label>
              <input type='text' name='company' className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
            </div>
            <div className='row align-items-end'>
              <div className='col-3'>
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.IDENTIFICATION")}</label>
                <input type='text' name='tag' className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
              </div>
              <div className='col-3'>
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.CATEGORY")}</label>
                <select name='category' className='form-select form-select-sm form-select-solid' onChange={(e: any) => changeParam(e)}>
                  <option value=''>{translateObj("MESSAGE.ALL")}</option>
                  <option value='Rent'>{translateObj("MESSAGE.RENT")}</option>
                  <option value='Sell'>{translateObj("MESSAGE.SALE")}</option>
                </select>
              </div>
              <div className='col-3'>
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.ZIPCODE")}</label>
                <input type='text' name='zip_code' className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
              </div>
              <div className="col-3">
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.STATUS")}</label>
                <select name='status' className='form-select form-select-sm form-select-solid' onChange={(e: any) => changeParam(e)}>
                  <option value='' selected={status === ""}>{translateObj("MESSAGE.ALL")}</option>
                  <option value="Waiting_Payment" selected={status === "Waiting_Payment"}>{translateObj("MESSAGE.WAITINGPAYMENT")}</option>
                  <option value="Waiting_Service" selected={status === "Waiting_Service"}>{translateObj("MESSAGE.WAITINGSERIVCE")}</option>
                  <option value="In_Progress" selected={status === "In_Progress"}>{translateObj("MESSAGE.INPROGRESS")}</option>
                  <option value="Waiting_Customer" selected={status === "Waiting_Customer"}>{translateObj("MESSAGE.CUSTOMERCHANGE")}</option>
                  <option value="In_Review" selected={status === "In_Review"}>{translateObj("MESSAGE.REVIEWCOORDINATION")}</option>
                  <option value="In_Review_Squad" selected={status === "In_Review_Squad"}>{translateObj("MESSAGE.REVIEWSQUAD")}</option>
                  <option value="Finished" selected={status === "Finished"}>{translateObj("MESSAGE.FINISHED")}</option>
                </select>
              </div>
            </div>
            <div className="mt-5">
              <button type="submit" className='w-100 btn btn-dark text-white' onClick={sendFilter}>
                <i className='fa fa-search'></i>
                <span>{translateObj("BTN.SEARCH")}</span>
              </button>
            </div>
          </div>

          <h3 className='mb-5'>{orders?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
                <tr>
                  <td>{translateObj("MESSAGE.TEAM")}</td>
                  <td>{translateObj("MESSAGE.COMPANY")}</td>
                  <td>{translateObj("MESSAGE.IDENTIFICATION")}</td>
                  <td>{translateObj("MESSAGE.ZIPCODE")}</td>
                  <td>{translateObj("MESSAGE.TYPE")}</td>
                  <td>{translateObj("MESSAGE.CATEGORY")}</td>
                  <td>{translateObj("MESSAGE.DECORATED")}</td>
                  <td>{translateObj("MESSAGE.STATUS")}</td>
                  <td>{translateObj("MESSAGE.PRODUCT")}</td>
                  <td>{translateObj("MESSAGE.CREATED_AT")}</td>
                  <td className='text-center'>#</td>
                </tr>
              </thead>
              <tbody className='text-center'>
                {listOrders?.length ? (listOrders?.map(itemObject => {
                  let item = itemObject.order

                  return (
                    <tr key={item.id} className={`cursor-pointer border-bottom ${item?.company_id === "2351b304-f7f0-4ed5-bc51-8dbd9255b1ac" && "bg-secondary"}`}>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')}>{item?.team?.name ? item?.team?.name : '----'}</td>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')} style={numberOfLines} className={`${item?.company_id === "2351b304-f7f0-4ed5-bc51-8dbd9255b1ac" && "text-primary"}`} title={item.company.name}>{item.company.name}</td>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')} style={numberOfLines} title={item.name}>{item.name}</td>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')} style={numberOfLines} title={item.property.zip_code}>{maskCep(item.property.zip_code)}</td>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')}>{searchType(item.property.type)}</td>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')}>{searchCategory(item.property.category)}</td>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')}>{searchDecoration(item.decoration)}</td>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')}>
                        <span className={`badge ${searchStatusColor(item.status)}`}>
                          {searchStatus(item.status)}
                        </span>
                      </td>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')}>{typeProductName(item)}</td>
                      <td onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')}>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td>
                      <td className='text-center'>
                        {item.status === 'Draft' ? (
                          <span className='p-3'>
                            <i className='fa fa-trash' onClick={() => confirmModal(item)}></i>
                          </span>
                        ) : (
                          <span className='p-3'>
                            <i
                              className='fa fa-search'
                              onClick={() => window.open(`/detailsProject/${item.id}`, '_blank')}
                            ></i>
                          </span>
                        )}
                      </td>
                    </tr>
                  )
                })) : (
                  <tr>
                    <td colSpan={12}>{translateObj("MESSAGE.NOTRESULT")}</td>
                  </tr>
                )}
              </tbody>
            </table>

            {totalPages?.length > 0 && (
              <div className="float-end my-5 text-center w-100">
                {translateObj("MESSAGE.PAGINATION")}
                <div className="d-flex pt-3">
                  {totalPages.map(item => {
                    return (
                      <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalConfirm
        translateObj={translateObj}
        show={modalConfirm}
        setShow={setModalConfirm}
        onConfirmInactivation={onConfirmInactivation}
        loading={loading}
      />
    </>
  )
}