import { Field, ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { maskCep, maskMoney } from '../../../../../utils'
import { useEffect, useState } from 'react';
import { ModalImage } from '../../ModalImage';
import { getCompanyAll } from '../../../../../services/Api';
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5';


export default function Step1(props: { translateObj: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, loading: boolean, typeSelect: any, user: any }) {
  const { translateObj, setFieldValue, loading, typeSelect, user } = props

  const [listCompany, setListCompany] = useState<any[]>([]);
  const [descriptionDecoration, setDescriptionDecoration] = useState<any>();
  const [type, setType] = useState('');
  const [showImage, setShowImage] = useState(false);

  const listTypes = (typeSelect?.celer) ? [
    {value: '', name: translateObj("MESSAGE.SELECT")},
    {value: 'Residential', name: translateObj("MESSAGE.RESIDENTIAL")},
    {value: 'Commercial', name: translateObj("MESSAGE.COMMERCIAL")},
    {value: 'Others', name: translateObj("MESSAGE.OTHERS")}, 
  ] : [
    {value: '', name: translateObj("MESSAGE.SELECT")},
    {value: 'Residential', name: translateObj("MESSAGE.RESIDENTIAL")}
  ]

  const listDecorations = (typeSelect?.celer || typeSelect?.express) ? [
    { value: "", name: translateObj("MESSAGE.SELECT") },
    { value: "Empty", name: translateObj("MESSAGE.EMPTY") },
    { value: "Classic", name: translateObj("MESSAGE.CLASSIC") },
    { value: "Sophisticated", name: translateObj("MESSAGE.SOPHISTICATED") },
    { value: "Young", name: translateObj("MESSAGE.YOUNG") },
  ] : [
    { value: "", name: translateObj("MESSAGE.SELECT") },
    { value: "Modern", name: translateObj("MESSAGE.MODERN") },
    { value: "Scandinavian", name: translateObj("MESSAGE.SCANDINAVIAN") },
    { value: "Industrial", name: translateObj("MESSAGE.INDUSTRIAL") },
    { value: "Farmhouse", name: translateObj("MESSAGE.FARMHOUSE") },
    { value: "Luxury", name: translateObj("MESSAGE.LUXURY") },
  ]

  const verifyDecoration = (value: any) => {
    if (!value) {
      setDescriptionDecoration(null);
      setFieldValue('decoration', value);
      return;
    }

    const decorations = [
      { name: "Empty", description: translateObj("MESSAGE.DECORATION.EMPTY"), image: "media/models/empty.jpg" },
      { name: "Classic", description: translateObj("MESSAGE.DECORATION.CLASSIC"), image: "media/models/classic.jpg" },
      { name: "Sophisticated", description: translateObj("MESSAGE.DECORATION.SOPHISTICATED"), image: "media/models/sophisticated.jpg" },
      { name: "Young", description: translateObj("MESSAGE.DECORATION.YOUNG"), image: "media/models/young.jpg" }
    ];

    setDescriptionDecoration(decorations.find(item => item.name.includes(value)));
    setFieldValue('decoration', value);
  }

  const verifyType = (value: any) => {
    setType(value);
    setFieldValue('type', value);
    if(value === 'Commercial') {
      setFieldValue('decoration', 'Classic');
      setDescriptionDecoration('Classic');
    }
  }

  const renderTooltip = (title: string, content?: string) => (
    <Tooltip id="button-tooltip">
      {content || title}
    </Tooltip>
  );

  useEffect(() => {
    getCompanyAll().then(data => {
      setListCompany(data);
    });

    const initialDecoration = "Empty";
    verifyDecoration(initialDecoration);
  }, []);

  return (
    <>
      <div className='w-100'>
        <h2 className='fw-bolder text-dark'>{translateObj("MESSAGE.IMMOBILE.TITLE")}</h2>

        <div className='text-gray-400 fw-bold fs-6 mb-15'>
          {translateObj("MESSAGE.IMMOBILE.DATA")}
        </div>

        {(user?.type === 'Squad' || user?.type === 'Squadinator') && (
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.COMPANY")}</label>

            <Field as='select' name='companyId' className='form-select form-select-lg form-select-solid'>
              <option value=''>{translateObj("MESSAGE.SELECT")}</option>
              {listCompany && listCompany.map((item, key) => <option key={key} value={item.id}>{item.name}</option>)}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='companyId' />
            </div>
          </div>  
        )}

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.IDENTIFICATION")}</label>

          <Field 
          name='name'
          placeholder={translateObj("MESSAGE.IDENTIFICATION.PLACEHOLDER")}
          className='form-control form-control-lg form-control-solid' 
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
          }}/>
          <div className='text-danger mt-2'>
            <ErrorMessage name='name' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.CATEGORY")}</label>

          <Field as='select' name='category' className='form-select form-select-lg form-select-solid'>
            <option value=''>{translateObj("MESSAGE.SELECT")}</option>
            <option value='Rent'>{translateObj("MESSAGE.RENT")}</option>
            <option value='Sell'>{translateObj("MESSAGE.SALE")}</option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='category' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.VALUERENTSALE")} 
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(translateObj("MESSAGE.VALUERENTSALE.TOOLTIP"))}
            >
              <i className='fas fa-question-circle m-1'></i>
            </OverlayTrigger>
          </label>
          <Field name='price' className='form-control form-control-lg form-control-solid' maxLength={13} onKeyUp={(e: any) => setFieldValue('price', maskMoney(e.target.value))} />
          <div className='text-danger mt-2'>
            <ErrorMessage name='price' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.TYPE")}</label>

          <Field 
            as='select' 
            name='type' 
            className='form-select form-select-lg form-select-solid'
            onChange={(e: any) => verifyType(e.target.value)}
          >
            {listTypes.map((item, key) => <option key={key} value={item.value}>{item.name}</option>)}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='type' />
          </div>
          {typeSelect?.express && (
            <div className='text-gray-400 fw-bold fs-6 mt-2'>
              {translateObj("MESSAGE.TYPE.EXPRESS")}
            </div>
          )}
        </div>

        <div className={`fv-row mb-7 ${typeSelect?.express && 'd-none'}`}>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.PROPERTYSTANDARD")}</label>

          <Field as="select" name='product' id='product' className='form-select form-select-lg form-select-solid'>
            <option value="">{translateObj("MESSAGE.SELECT")}</option>
            <option value='High'>{translateObj("MESSAGE.HIGH")}</option>
            <option value='Average'>{translateObj("MESSAGE.AVERAGE")}</option>
            <option value='Economic'>{translateObj("MESSAGE.ECONOMIC")}</option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='product' />
          </div>
        </div>

        <div className={`fv-row mb-7 ${(type === 'Commercial' || typeSelect?.express) && 'd-none'}`}>
          <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.DECORATIONSTYLE")}
          <span className="optional">({translateObj("MESSAGE.OPTIONAL")})</span>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(translateObj("MESSAGE.DECORATIONSTYLE.TOOLTIP"))}
            >
              <i className='fas fa-question-circle m-1'></i>
            </OverlayTrigger>
          </label>
          <Field 
            as='select'
            name='decoration' 
            className={`form-select form-select-lg form-select-solid`}  
            onChange={(e: any) => verifyDecoration(e.target.value)}
          >
            {listDecorations.map((item, key) => <option key={key} value={item.value}>{item.name}</option>)}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='decoration' />
          </div>

          {descriptionDecoration && descriptionDecoration.name && (
            <div className='my-5'>
              <h4>{translateObj("MESSAGE.EXAMPLEDECORATION")}</h4>
              <div className='d-flex gap-5 cursor-pointer' onClick={() => setShowImage(true)}>
                <img src={descriptionDecoration.image} style={{ maxHeight: '120px' }} alt={translateObj("MESSAGE.EXAMPLEDECORATION")} />
                <p>{descriptionDecoration.description}</p>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label'>
            {translateObj("MESSAGE.ZIPCODE")}
            <span className="optional">({translateObj("MESSAGE.OPTIONAL")})</span>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(translateObj("MESSAGE.ZIPCODE.TOOLTIP"))}
            >
              <i className='fas fa-question-circle m-1'></i>
            </OverlayTrigger>
          </label>

          <Field name='zip_code' className='form-control form-control-lg form-control-solid' maxLength={9} onKeyUp={(e: any) => setFieldValue('zip_code', maskCep(e.target.value))} />
          <div className='text-danger mt-2'>
            <ErrorMessage name='zip_code' />
          </div>
        </div>

        <div className='d-flex flex-stack pt-15'>
          <div className='mr-2'></div>
          <div>
            {loading ? (
              <span className='text-center indicator-progress' style={{ display: 'block' }}>
                {translateObj("MESSAGE.LOADING") + ' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <button type='submit' className='btn btn-lg btn-dark text-white me-3'>
                <span className='indicator-label'>
                  {translateObj("BTN.NEXT")}
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>

      {descriptionDecoration && <ModalImage translateObj={translateObj} show={showImage} setShow={setShowImage} descriptionDecoration={descriptionDecoration} />}
    </>
  )
}