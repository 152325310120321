import axios from 'axios'
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';

const API_URL = process.env.REACT_APP_API_URL

export const POST_PROPERTY = `${API_URL}/property`
export const POST_ORDER = `${API_URL}/order`
export const POST_ORDER_PHOTO = `${API_URL}/order/photo`
export const PUT_ORDER_SEND = `${API_URL}/order/send`
export const PUT_ORDER_PAYMENT = `${API_URL}/order/payment`
export const GET_ORDER = `${API_URL}/order`
export const GET_ORDER_ID = `${API_URL}/order/`
export const GET_ADMIN_ORDER = `${API_URL}/admin/orders`
export const GET_ADMIN_ORDER_ID = `${API_URL}/admin/order/`
export const POST_ADMIN_ORDER_PHOTO = `${API_URL}/admin/order/photo`
export const PUT_ADMIN_ORDER_PHOTOS_REJECT = `${API_URL}/admin/order/photos/reject`
export const PATCH_ADMIN_ORDER = `${API_URL}/admin/order`
export const GET_COMPANY_USERS = `${API_URL}/company/users`
export const POST_AUTH_SIGN_UP = `${API_URL}/auth/sign-up`
export const PUT_ADMIN_DELAY = `${API_URL}/admin/delay`
export const PUT_ADMIN_TEAM = `${API_URL}/admin/team`
export const GET_ADMIN_DASHBOARD = `${API_URL}/admin/dashboard`
export const PUT_ADMIN_ORDER_PHOTO_REMOVE = `${API_URL}/admin/order/photo/remove`
export const PUT_ADMIN_OPERATOR = `${API_URL}/admin/operator`
export const GET_ADMIN_TEAMS = `${API_URL}/admin/teams`
export const PATCH_AUTH_USER = `${API_URL}/auth/user`
export const POST_PAYMENT_CREDIT = `${API_URL}/payment/credit`
export const POST_PAYMENT_CREDIT_PAY = `${API_URL}/payment/credit/pay`
export const GET_PAYMENT_CREDIT = `${API_URL}/payment/credit`
export const GET_PAYMENT_CREDITS = `${API_URL}/payment/credits`
export const GET_PAYMENT_USAGE_CREDITS = `${API_URL}/payment/usage`
export const GET_PAYMENT_BUDGET_IMAGE_AMOUNT = `${API_URL}/payment/budget/image/`
export const GET_ADMIN_COMPANIES = `${API_URL}/admin/companies`
export const GET_ADMIN_COMPANY_ID = `${API_URL}/admin/company/`
export const PUT_ADMIN_ENVIROMENT_APPROVE_ID = `${API_URL}/admin/enviroment/approve/`
export const GET_USERS = `${API_URL}/users`
export const POST_USERS = `${API_URL}/users`
export const GET_USERS_TEAM = `${API_URL}/users/teams`
export const POST_USERS_TEAM = `${API_URL}/users/team`
export const GET_USERS_ID = `${API_URL}/users/`
export const PATCH_USERS_ID = `${API_URL}/users/`
export const DELETE_USERS_ID = `${API_URL}/users/`
export const GET_COUPON_PAYMENTS = `${API_URL}/admin/coupon-payments`
export const GET_COUPONS = `${API_URL}/cupons`
export const GET_COUPON_ID = `${API_URL}/cupons/`
export const PATCH_COUPON_ID = `${API_URL}/cupons/`
export const DELETE_COUPON_ID = `${API_URL}/cupons/`
export const POST_COUPON = `${API_URL}/cupons`
export const GET_ADMIN_TEAMS_CHOICE = `${API_URL}/admin/teams-choice`
export const PATCH_USERS_TEAM_ID = `${API_URL}/users/team/`
export const PUT_ADMIN_ORDER_PHOTO_CHOSE_ID = `${API_URL}/admin/order/photo/chose/`
export const PATCH_AUTH_USER_PASSWORD = `${API_URL}/auth/user/password`
export const GET_PAYMENT_ID = `${API_URL}/payment/`
export const POST_PAYMENT_CREDIT_PAY_PDV = `${API_URL}/payment/credit/pay/pdv`
export const GET_ADMIN_PAYMENTS = `${API_URL}/admin/payments`
export const GET_ADMIN_PAYMENTS_USAGE = `${API_URL}/admin/payments/usage`
export const DELETE_ORDER_ID = `${API_URL}/order/`
export const DELETE_ADMIN_ORDER_ID = `${API_URL}/admin/order/`
export const DELETE_USERS_TEAM_ID = `${API_URL}/users/team/`
export const GET_EXECUTIVE = `${API_URL}/executive`
export const GET_INFLUENCER = `${API_URL}/influencer`
export const PATCH_COMPANY_EXECUTIVE = `${API_URL}/company/executive`
export const GET_INFLUENCER_DASHBOARD = `${API_URL}/influencer/dashboard`
export const GET_EXECUTIVE_DASHBOARD = `${API_URL}/executive/dashboard`
export const POST_ADMIN_MANUAL_RELEASE = `${API_URL}/admin/manual-release`
export const PATCH_COMPANY = `${API_URL}/company`
export const POST_ADMIN_AI_NEW_VARIATION = `${API_URL}/admin/ai/new-variation`
export const PUT_ADMIN_AI_PHOTO_CHOOSE_ID = `${API_URL}/admin/ai/photo/choose/`
export const GET_COMPANY_ALL = `${API_URL}/company/all`
export const POST_ADMIN_ORDER_COMPARATIVE = `${API_URL}/admin/order/comparative`
export const GET_NOTIFICATIONS = `${API_URL}/notifications`
export const GET_NOTIFICATIONS_COUNT = `${API_URL}/notifications/count`
export const PATCH_NOTIFICATIONS_ID_READ = `${API_URL}/notifications/:id/read`
export const GET_ADMIN_GENERATE_PDF_COMPANY_ID = `${API_URL}/admin/generate-pdf/company/`
export const GET_ADMIN_GENERATE_PDF_PAYMENT_ID = `${API_URL}/admin/generate-pdf/payment/`
export const GET_ORDER_EXTERNAL_ID = `${API_URL}/order/external/`
export const PUT_IMAGES_COMPANY_ID = `${API_URL}/admin/images/company/`
export const PATCH_INACTIVE_ORDER_ID = `${API_URL}/order/inactive/`

function Translate(props: { obj: any }) {
  let { obj } = props;
  const intl = useIntl();
  return <>{intl.formatMessage({ id: obj })}</>
}

const logoutCatch = () => {
  localStorage.clear()
  window.location.reload()
}

const successToast = () => {
  toast.success(<Translate obj={`MESSAGE.SAVEDSUCCESS`} />, { position: 'bottom-right' })
}

const errorToast = (text = '') => {
  toast.error(text ? text : <Translate obj={`MESSAGE.FAILURE`} />, { position: 'bottom-right' })
}

export function postProperty(data: any) {
  return axios.post(POST_PROPERTY, data).then(response => {
    // successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function postOrder(data: any) {
  return axios.post(POST_ORDER, data).then(response => {
    // successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function postOrderPhoto(data: any) {
  return await axios.post(POST_ORDER_PHOTO, data).then(response => {
    // successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putOrderSend(data: any) {
  return axios.put(PUT_ORDER_SEND, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putOrderPayment(data: any) {
  return axios.put(PUT_ORDER_PAYMENT, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast(err?.response?.data?.message)
    err?.response?.status === 401 && logoutCatch()
    return { error: true }
  })
}

export function getOrder(data: any) {
  return axios.get(GET_ORDER, { params: data }).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getOrderId(id: any) {
  return axios.get(GET_ORDER_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminOrder(data: any) {
  return axios.get(GET_ADMIN_ORDER, { params: data }).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminOrderId(id: any) {
  return axios.get(GET_ADMIN_ORDER_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {

    err?.response?.status === 401 && logoutCatch()
  })
}

export async function postAdminOrderPhoto(data: any) {
  return await axios.post(POST_ADMIN_ORDER_PHOTO, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putAdminOrderPhotosReject(data: any) {
  return axios.put(PUT_ADMIN_ORDER_PHOTOS_REJECT, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchAdminOrder(data: any) {
  return await axios.patch(PATCH_ADMIN_ORDER, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getCompanyUsers() {
  return axios.get(GET_COMPANY_USERS).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function postAuthSignUp(data: any) {
  return axios.post(POST_AUTH_SIGN_UP, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast(err?.response?.data?.message)
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putAdminDelay(data: any) {
  return axios.put(PUT_ADMIN_DELAY, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putAdminTeam(data: any) {
  return axios.put(PUT_ADMIN_TEAM, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminDashboard() {
  return axios.get(GET_ADMIN_DASHBOARD).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putAdminOrderPhotoRemove(data: any) {
  return axios.put(PUT_ADMIN_ORDER_PHOTO_REMOVE, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putAdminOperator(data: any) {
  return axios.put(PUT_ADMIN_OPERATOR, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminTeams(data: any) {
  return axios.get(data ? GET_ADMIN_TEAMS + '/' + data : GET_ADMIN_TEAMS).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchAuthUser(data: any) {
  return await axios.patch(PATCH_AUTH_USER, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function postPaymentCredit(data: any) {
  return await axios.post(POST_PAYMENT_CREDIT, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast(err?.response?.data?.message)
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function postPaymentCreditPay(data: any) {
  return await axios.post(POST_PAYMENT_CREDIT_PAY, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getPaymentCredit() {
  return axios.get(GET_PAYMENT_CREDIT).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getPaymentCredits() {
  return axios.get(GET_PAYMENT_CREDITS).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getPaymentUsageCredits() {
  return axios.get(GET_PAYMENT_USAGE_CREDITS).then(response => {
    return response.data
  }).catch((err: any) => {
    (err?.response?.status === 401 || err?.response?.status === 404) && logoutCatch()
    err?.response?.status === 400 && errorToast()
  })
}

export function getPaymentBudgetAmount(image: boolean, amount: any) {
  return axios.get(GET_PAYMENT_BUDGET_IMAGE_AMOUNT + amount + "/" + image).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminCompanies(data: any) {
  return axios.get(GET_ADMIN_COMPANIES, { params: data }).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminCompanyId(id: any) {
  return axios.get(GET_ADMIN_COMPANY_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putAdminEnviromentApproveId(id: any) {
  return axios.put(PUT_ADMIN_ENVIROMENT_APPROVE_ID + id).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function postUsers(data: any) {
  return axios.post(POST_USERS, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getUsersTeam() {
  return axios.get(GET_USERS_TEAM).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function postUsersTeam(data: any) {
  return axios.post(POST_USERS_TEAM, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getUsers() {
  return axios.get(GET_USERS).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getUsersId(id: any) {
  return axios.get(GET_USERS_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchUsersId(id: any, data: any) {
  return await axios.patch(PATCH_USERS_ID + id, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function deleteUsersId(id: any) {
  return await axios.delete(DELETE_USERS_ID + id).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getCouponPayments() {
  return axios.get(GET_COUPON_PAYMENTS).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getCoupons() {
  return axios.get(GET_COUPONS).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getCouponId(id: any) {
  return axios.get(GET_COUPON_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchCouponId(id: any, data: any) {
  return await axios.patch(PATCH_COUPON_ID + id, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function deleteCouponId(id: any) {
  return await axios.patch(DELETE_COUPON_ID + id, { is_active: false }).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function postCoupon(data: any) {
  return axios.post(POST_COUPON, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminTeamsChoice() {
  return axios.get(GET_ADMIN_TEAMS_CHOICE).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchUsersTeamId(id: any, data: any) {
  return await axios.patch(PATCH_USERS_TEAM_ID + id, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putAdminOrderPhotoChoseId(id: any) {
  return axios.put(PUT_ADMIN_ORDER_PHOTO_CHOSE_ID + id).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchAuthUserPassword(data: any) {
  return await axios.patch(PATCH_AUTH_USER_PASSWORD, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getPaymentId(id: any) {
  return axios.get(GET_PAYMENT_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function postPaymentCreditPayPdv(data: any) {
  return axios.post(POST_PAYMENT_CREDIT_PAY_PDV, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast(err?.response?.data?.message)
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminPayments(data: any) {
  return axios.get(GET_ADMIN_PAYMENTS, { params: data }).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminPaymentsUsage(data: any) {
  return axios.get(GET_ADMIN_PAYMENTS_USAGE, { params: data }).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function deleteOrderId(id: any) {
  return await axios.delete(DELETE_ORDER_ID + id).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function deleteAdminOrderId(id: any) {
  return await axios.delete(DELETE_ADMIN_ORDER_ID + id).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function deleteUsersTeamId(id: any) {
  return await axios.delete(DELETE_USERS_TEAM_ID + id).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getExecutive() {
  return axios.get(GET_EXECUTIVE).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getInfluencer() {
  return axios.get(GET_INFLUENCER).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchCompanyExecutive(data: any) {
  return await axios.patch(PATCH_COMPANY_EXECUTIVE, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getInfluencerDashboard() {
  return axios.get(GET_INFLUENCER_DASHBOARD).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getExecutiveDashboard() {
  return axios.get(GET_EXECUTIVE_DASHBOARD).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function postAdminManualRelease(data: any) {
  return axios.post(POST_ADMIN_MANUAL_RELEASE, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchCompany(data: any) {
  return await axios.patch(PATCH_COMPANY, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function postAdminAiNewVariation(data: any) {
  return await axios.post(POST_ADMIN_AI_NEW_VARIATION, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putAdminAiPhotoChooseId(id: any) {
  return axios.put(PUT_ADMIN_AI_PHOTO_CHOOSE_ID + id).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getCompanyAll() {
  return axios.get(GET_COMPANY_ALL).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function postAdminOrderComparative(data: any) {
  return await axios.post(POST_ADMIN_ORDER_COMPARATIVE, data).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getNotifications() {
  return axios.get(GET_NOTIFICATIONS).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getNotificationsCount() {
  return axios.get(GET_NOTIFICATIONS_COUNT).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchNotificationsIdRead(id: any) {
  return await axios.patch(PATCH_NOTIFICATIONS_ID_READ.replace(":id", id)).then(response => {
    successToast()
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminGeneratePdfCompanyId(id: any) {
  return axios.get(GET_ADMIN_GENERATE_PDF_COMPANY_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getAdminGeneratePdfPaymentId(id: any) {
  return axios.get(GET_ADMIN_GENERATE_PDF_PAYMENT_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function getOrderExternalId(id: any) {
  return axios.get(GET_ORDER_EXTERNAL_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export function putImagesCompanyId(id: any, data: any) {
  return axios.put(PUT_IMAGES_COMPANY_ID + id, data).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 401 && logoutCatch()
  })
}

export async function patchInactiveOrder(id: string) {
  return axios.patch(PATCH_INACTIVE_ORDER_ID + id).then(response => {
    return response.data
  }).catch((err: any) => {
    err?.response?.status === 400 && errorToast()
    err?.response?.status === 401 && logoutCatch()
  })
}