import {FC} from 'react'
import {useIntl} from 'react-intl'

const ContactUs: FC = () => {
  const intl = useIntl()

  const translateObj = (obj: string, varInfo?: Record<string, any>) => {
    if (varInfo) {
      return intl.formatMessage({id: obj}, varInfo)
    }
    return intl.formatMessage({id: obj})
  }

  return (
    <div>
      <h1 className='text-uppercase'>{translateObj('MESSAGE.CONTACTUS')}</h1>
      <p>{translateObj('MESSAGE.CONTACTUS.DESCRIPTION')}</p>
      <div className='row'>
        {/* Card do WhatsApp */}
        <div className='col-md-6 col-sm-12 my-5'>
          <a
            href='https://wa.me/31971354449'
            target='_blank'
            rel='noopener noreferrer'
            className='text-dark'
          >
            <div className='card shadow-lg'>
              <div className='card-body'>
                <div className='d-flex gap-5 align-items-center'>
                  <div>
                    <i className='far fa-comment fa-5x text-success'></i>
                  </div>
                  <div>
                    <h5 className='card-title'>{translateObj('MESSAGE.WHATSAPP.TITLE')}</h5>
                    <p className='card-text'>{translateObj('MESSAGE.WHATSAPP.DESC')}</p>
                  </div>
                </div>
                <button type='button' className='btn btn-success text-white w-100 mt-10'>
                  {translateObj('MESSAGE.WHATSAPP.BUTTON')}
                </button>
              </div>
            </div>
          </a>
        </div>

        {/* Card do E-mail */}
        <div className='col-md-6 col-sm-12 my-5'>
          <a
            href='mailto:contato@byceler.com.br'
            className='text-dark'
          >
            <div className='card shadow-lg'>
              <div className='card-body'>
                <div className='d-flex gap-5 align-items-center'>
                  <div>
                    <i className='far fa-envelope fa-5x text-info'></i>
                  </div>
                  <div>
                    <h5 className='card-title'>{translateObj('MESSAGE.EMAIL.TITLE')}</h5>
                    <p className='card-text'>{translateObj('MESSAGE.EMAIL.DESC')}</p>
                  </div>
                </div>
                <button type='button' className='btn btn-info text-white w-100 mt-10'>
                  {translateObj('MESSAGE.EMAIL.BUTTON')}
                </button>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export {ContactUs}
